<template>
  <v-card elevation="0" class="pa-0 h-100" style="background-color: #f9f9f9">
    <HeaderModule
      title="Dashboard"
      description="Easily compare data across areas"
      icon="view-dashboard"
    />
    <!-- the parent card/tile -->
    <v-card
      id="main-container"
      tile
      elevation="0"
      class="pa-3 mt-3 h-100"
      style="background-color: #f9f9f9"
    >
      <v-row id="dashboardButtons" class="px-3">
        <v-col class="d-flex pa-0 justify-start">
          <DashboardDataSelector
            :data="initialData"
            :standardIndicatorsAsObject="standardIndicatorsAsObject"
            v-model:showDialog="showDataComponentDialog"
            v-model:saveProcessOngoing="saveCustomDashboardProcessOngoing"
            v-model:customDashboardToEdit="customDashboardToEdit"
            :disabled="loadingData"
            @load-new-indicators="loadIndicatorsFromDataComponent"
            @save-custom-dashboard="saveEditedCustomDashboard"
          />
          <v-btn
            color="primary"
            variant="elevated"
            tile
            @click="showAreaSelector = true"
            :disabled="loadingData"
            aria-label="Areas"
            prepend-icon="mdi-vector-polygon"
          >
            change areas
          </v-btn>
        </v-col>
        <v-col class="d-flex pa-0 justify-end">
          <v-btn
            v-if="showSaveCustomDashboardButton"
            color="success"
            variant="elevated"
            tile
            :disabled="loadingData"
            @click="showSaveCustomDashboardDialog = true"
            aria-label="Save custom dashboard"
            prepend-icon="mdi-plus"
            >Save dashboard
          </v-btn>

          <v-btn
            v-if="!defaultDashboardLoaded"
            color="primary"
            variant="elevated"
            tile
            :disabled="loadingData"
            @click="viewDefaultDashboard()"
            aria-label="Show default"
            prepend-icon="mdi-list-box-outline"
            >Show default
          </v-btn>

          <v-btn
            color="primary"
            variant="elevated"
            tile
            @click="download"
            :loading="exportingCSV"
            :disabled="loadingData || exportingCSV"
            :aria-label="
              isDemoAccount
                ? 'Downloading unavailable during the trial'
                : 'Export'
            "
            prepend-icon="mdi-file-export-outline"
          >
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
            <v-tooltip v-if="isDemoAccount" activator="parent" location="top"
              >Downloading unavailable during the trial</v-tooltip
            >
            Export
          </v-btn>

          <AccessibilityDialogueOnDashboard :loadingData="loadingData" />

          <v-btn
            v-if="userHasCustomDashboardSaves"
            color="primary"
            variant="elevated"
            tile
            :disabled="loadingData"
            @click="showCustomDashboardDialog = true"
            aria-label="Custom dashboards"
            prepend-icon="mdi-heart-outline"
            >saved dashboards
          </v-btn>
        </v-col>
      </v-row>
      <v-card
        id="dashboardLegend"
        class="d-flex my-5"
        style="border: solid 1px #d2d2d2; margin: 20px 0px 15px 0px"
        elevation="0"
      >
        <DashboardLegend :clientColourScheme="clientColourScheme" />
      </v-card>
      <v-card class="mb-3" style="border: solid 1px #d2d2d2">
        <v-card-actions
          v-if="loadingData"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 70vh;
          "
        >
          <v-progress-circular
            class="spinner"
            v-if="loadingPercentages"
            :size="180"
            :width="2"
            color="#51627C"
            indeterminate
          >
            <div
              class="text-center"
              v-html="
                'Loading...' +
                progressPercentage +
                '%,<br>This could take a<br>few minutes.'
              "
            />
          </v-progress-circular>
          <v-progress-circular
            v-else
            class="spinner"
            :size="180"
            :width="2"
            color="#51627C"
            indeterminate
          >
            <div
              class="text-center"
              v-html="'Loading...this could<br>take a minute.'"
            />
          </v-progress-circular>
        </v-card-actions>
        <v-card-actions
          v-if="!loadingData && !customAreasToLoad?.length"
          class="mx-auto d-flex justify-center align-center"
          style="width: 360px; min-height: 70vh"
        >
          <AvatarState avatarSize="102" icon="mdi-view-dashboard" iconSize="54">
            <template #title>Dashboard Setup Needed </template>
            <template #body>
              To get started with your analysis, add at least one Custom Area
              and select a dataset. Once set up, you'll be able to unlock
              insights and make data-driven decisions with ease.
            </template>
            <template #footer>
              <div>
                <v-btn
                  prepend-icon="mdi-plus"
                  variant="elevated"
                  color="primary"
                  @click="showAreaSelector = true"
                  rounded="0"
                  class="w-100 mt-5 mb-1"
                  >select areas</v-btn
                >
              </div>
              <div>
                <v-btn
                  variant="text"
                  density="compact"
                  color="primary"
                  class="w-50"
                  href="https://support.localinsight.org/en/collections/621055-dashboard"
                  target="_blank"
                  >learn more</v-btn
                >
              </div>
            </template>
          </AvatarState>
        </v-card-actions>
        <!-- Start of data table -->
        <v-data-table
          v-if="displayTable && !loadingData"
          :items="dataInTable.custom_area_categories_displayed"
          hide-default-footer
          density="compact"
          id="ocsi-dashboard"
          fixed-header
          :style="`overflow-y: auto; max-height: ${calculatedTableHeight}px;`"
          :mobile="false"
        >
          <!-- headers -->
          <template v-slot:headers>
            <tr>
              <th
                v-for="(header, index) in dataInTable.tableHeaderArray"
                :key="header.key"
                class="header text-center"
                :style="{
                  'min-width': 240 + 'px',
                  'max-width': colHeaderWidth + 'px',
                }"
                aria-label="View metadata"
                tabindex="0"
                @keydown.enter="showMetadataDialog(header.key)"
                @click="showMetadataDialog(header.key)"
              >
                <v-tooltip v-if="index" location="bottom" :text="header.title">
                  <template #activator="{ props }">
                    <div
                      v-bind="props"
                      class="testOne headerText"
                      :style="{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }"
                    >
                      {{ header.title }}
                    </div>
                  </template>
                </v-tooltip>
                <div v-else>
                  {{ header.title }}
                </div>
              </th>
            </tr>
          </template>
          <template v-slot:body v-if="!loadingData">
            <!-- indicator metadata-->
            <tr>
              <td style="background-color: transparent">Reference Date</td>
              <td
                v-for="(ind, key) in dataInTable.indicators"
                :key="key"
                style="background-color: transparent"
              >
                {{ ind.date_coverage_text }}
              </td>
            </tr>
            <tr>
              <td style="background-color: transparent">Update Frequency</td>
              <td
                v-for="(ind, key) in dataInTable.indicators"
                :key="key"
                style="background-color: transparent"
              >
                {{ ind.update_frequency }}
              </td>
            </tr>
            <!-- data -->
            <tr>
              <td
                :colspan="dataInTable.tableHeaderArray.length"
                class="comparatorTitle text-start"
              >
                {{ nationalComparator.cac }}
              </td>
            </tr>
            <tr>
              <td class="comparator text-start">
                {{ nationalComparator.name }}
              </td>
              <td
                v-for="(ind, key) in dataInTable.indicators"
                :key="key"
                :style="{
                  'background-color':
                    '#' +
                    getColor('nc.' + '.' + ind.indicator_code + '.' + ind.date),
                }"
                :class="{
                  'light-text': isDarkColor(
                    getColor('nc.' + '.' + ind.indicator_code + '.' + ind.date),
                  ),
                }"
                :aria-description="
                  getAriaDescriptionText(
                    'nc.' + '.' + ind.indicator_code + '.' + ind.date,
                  ) ?? 'not given an equal interval band'
                "
              >
                {{
                  dataValuesDictionary[
                    "nc" + "." + ind.indicator_code + "." + ind.date
                  ] ?? "-"
                }}
              </td>
            </tr>

            <template
              v-for="cac in dataInTable.custom_area_categories_displayed"
              :key="cac.id"
            >
              <tr>
                <td
                  :colspan="dataInTable.tableHeaderArray.length"
                  class="cac text-start"
                >
                  {{ cac.name }}
                </td>
              </tr>
              <tr
                v-for="(ca, index) in getCustomAreasInCACForDisplay(cac.id)"
                :key="ca.id"
              >
                <td
                  class="ca text-start"
                  :id="'ca_' + index"
                  @click="
                    showCustomAreaDetailsDialog = true;
                    customAreaToShow = {};
                    customAreaToShow = getDetailedArea(ca);
                  "
                >
                  {{ ca.name }}
                </td>
                <td
                  v-for="(ind, key) in dataInTable.indicators"
                  :key="key"
                  :style="{
                    'background-color':
                      '#' +
                      getColor(
                        ca.id + '.' + ind.indicator_code + '.' + ind.date,
                      ),
                  }"
                  :class="{
                    'light-text': isDarkColor(
                      getColor(
                        ca.id + '.' + ind.indicator_code + '.' + ind.date,
                      ),
                    ),
                  }"
                  :aria-label="
                    getAriaDescriptionText(
                      ca.id + '.' + ind.indicator_code + '.' + ind.date,
                    ) ?? 'not given an equal interval band'
                  "
                >
                  <v-icon
                    class="accessibility-icon"
                    v-if="
                      $store.state.config.userProfile.show_icons_on_dashboard
                    "
                    :color="
                      isDarkColor(
                        getColor(
                          ca.id + '.' + ind.indicator_code + '.' + ind.date,
                        ),
                      )
                        ? '#FFFFFF'
                        : '#000000'
                    "
                    left
                    size="small"
                    :title="
                      getIcon(ca.id + '.' + ind.indicator_code + '.' + ind.date)
                        .level
                    "
                  >
                    {{
                      getIcon(ca.id + "." + ind.indicator_code + "." + ind.date)
                        .icon
                    }}
                  </v-icon>
                  {{
                    dataValuesDictionary[
                      ca.id + "." + ind.indicator_code + "." + ind.date
                    ] ?? "-"
                  }}
                </td>
              </tr>
            </template>

            <template v-if="getCustomAreasInCACForDisplay(null).length">
              <tr>
                <td
                  :colspan="dataInTable.tableHeaderArray.length"
                  class="cac text-start"
                >
                  Unassigned
                </td>
              </tr>
              <tr
                v-for="ca in getCustomAreasInCACForDisplay(null)"
                :key="ca.id"
              >
                <td
                  class="ca text-start"
                  :style="{
                    'min-width': 300 + 'px',
                    'max-width': colHeaderWidth + 'px',
                  }"
                  @click="
                    showCustomAreaDetailsDialog = true;
                    customAreaToShow = {};
                    customAreaToShow = getDetailedArea(ca);
                  "
                >
                  {{ ca.name }}
                </td>
                <td
                  v-for="(ind, key) in dataInTable.indicators"
                  :key="key"
                  :style="{
                    'background-color':
                      '#' +
                      getColor(
                        ca.id + '.' + ind.indicator_code + '.' + ind.date,
                      ),
                  }"
                  :class="{
                    'light-text': isDarkColor(
                      getColor(
                        ca.id + '.' + ind.indicator_code + '.' + ind.date,
                      ),
                    ),
                  }"
                  :aria-label="
                    getAriaDescriptionText(
                      ca.id + '.' + ind.indicator_code + '.' + ind.date,
                    ) ?? 'not given an equal interval band'
                  "
                >
                  <v-icon
                    class="accessibility-icon"
                    v-if="
                      $store.state.config.userProfile.show_icons_on_dashboard
                    "
                    :color="
                      isDarkColor(
                        getColor(
                          ca.id + '.' + ind.indicator_code + '.' + ind.date,
                        ),
                      )
                        ? '#FFFFFF'
                        : '#000000'
                    "
                    left
                    size="small"
                    :title="
                      getIcon(ca.id + '.' + ind.indicator_code + '.' + ind.date)
                        .level
                    "
                  >
                    {{
                      getIcon(ca.id + "." + ind.indicator_code + "." + ind.date)
                        .icon
                    }}
                  </v-icon>
                  {{
                    dataValuesDictionary[
                      ca.id + "." + ind.indicator_code + "." + ind.date
                    ] ?? "-"
                  }}
                </td>
              </tr>
            </template>
          </template>
        </v-data-table>
      </v-card>
    </v-card>

    <IndicatorDetailsDialog
      v-model:dialog="showAboutIndDialog"
      :indicator="viewInfo?.indicatorInfo"
      :height="40"
    />
    <CustomAreaSelector
      v-model:dialog="showAreaSelector"
      :markerCount="0"
      :clearDialogs="true"
      @selectedCustomAreas="loadNewAreas"
    />

    <!--    custom area details dialogue    -->
    <customAreaDetails
      :display="showCustomAreaDetailsDialog"
      :area="customAreaToShow"
      @close="handleClose"
    />

    <v-dialog v-model="showCustomDashboardDialog" scrollable max-width="800px">
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-center"
        >
          <v-icon
            style="position: absolute"
            class="ml-4"
            @click="showCustomDashboardDialog = false"
            title="close"
            >mdi-close
          </v-icon>
          <v-spacer>
            <v-toolbar-title>Custom Dashboards</v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row
            class="mt-2 mb-2"
            v-for="(item, index) in initialData.user_dashboards"
            :key="index"
          >
            <v-col cols="12" sm="5" md="5" class="custom-dashboard-col">
              <v-card-title class="pa-0 ma-0 mt-3"
                ><span
                  class="custom-dash-list-item"
                  style="cursor: pointer"
                  :title="item.name"
                  @click="initiateViewCustomDashboard(item)"
                  >{{ item.name }}</span
                ></v-card-title
              >
            </v-col>
            <v-col cols="12" sm="1" md="1" class="custom-dashboard-col">
              <div class="mt-2">
                <v-btn
                  size="small"
                  color="success"
                  variant="elevated"
                  dark
                  tile
                  @click="initiateViewCustomDashboard(item)"
                  aria-label="View"
                  title="view"
                >
                  View
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="1" md="1" class="custom-dashboard-col">
              <div class="mt-2">
                <v-btn
                  size="small"
                  color="warning"
                  variant="elevated"
                  dark
                  tile
                  @click="initiateEditCustomDashboard(item)"
                  aria-label="edit"
                  title="edit"
                >
                  edit
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="custom-dashboard-col">
              <v-checkbox
                v-model="defaultDashboardID"
                label="Default Dashboard"
                title="default dashboard"
                :value="item.id"
                hide-details
                @change="saveAsDefaultDashboard"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="custom-dashboard-col">
              <div class="mt-2">
                <v-btn
                  size="small"
                  variant="elevated"
                  color="error"
                  dark
                  tile
                  :id="'deleteCustomDashboard_' + index"
                  @click="initiateDeleteCustomDashboard(item)"
                  title="delete"
                  aria-label="delete"
                >
                  delete
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-text
            v-if="!initialData.user_dashboards.length"
            class="text-sm-h6 text-center"
          >
            <v-spacer> You have no custom dashboards </v-spacer>
          </v-card-text>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="error"
            variant="elevated"
            tile
            @click="showCustomDashboardDialog = false"
            aria-label="Close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showDeleteCustomDashboardConfirmDialog"
      max-width="500px"
    >
      <v-toolbar
        :style="
          'background-color: ' +
          this.$store.state.config.siteConfig.toolbar_colour +
          '; color:#ffffff;'
        "
        class="text-center"
        max-height="75px"
      >
        <v-spacer>
          <v-toolbar-title>Confirm custom dashboard delete</v-toolbar-title>
        </v-spacer>
      </v-toolbar>
      <v-card>
        <v-card-text class="text-center py-4">
          Are you sure you want to delete the custom dashboard
          <b>{{ customDashboardToDeleteName }}</b
          >?
          <br />
          <v-spacer>This action cannot be undone.</v-spacer>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="success"
            variant="elevated"
            tile
            @click="showDeleteCustomDashboardConfirmDialog = false"
            aria-label="Cancel"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            variant="elevated"
            tile
            @click="deleteCustomDashboard"
            aria-label="delete"
          >
            delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showSaveCustomDashboardDialog"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-center"
        >
          <v-spacer>
            <v-toolbar-title>Save your custom dashboard </v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-text-field
          class="mt-4 ml-4 mr-4 fields"
          clearable
          hide-details="true"
          label="Name your custom dashboard"
          placeholder="custom-dashboard"
          variant="outlined"
          density="compact"
          rounded="0"
          v-model="customDashboardName"
          autocomplete="off"
        ></v-text-field>
        <v-list density="compact">
          <v-card-text>The indicators being saved are</v-card-text>
          <v-list-item
            v-for="(item, index) in dataInTable.indicators"
            :key="item.id"
            :subtitle="index + 1 + '. ' + item.indicator_name"
          >
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-card-actions class="py-0 px-1">
          <v-btn
            color="error white--text"
            variant="elevated"
            tile
            @click="showSaveCustomDashboardDialog = false"
            aria-label="Close"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            variant="elevated"
            :disabled="!customDashboardName"
            tile
            @click="saveCustomDashboard"
            aria-label="Save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import CustomAreaSelector from "@/components/CustomAreaSelector";
import DashboardDataSelector from "@/components/DashboardDataSelector";
import IndicatorDetailsDialog from "@/components/IndicatorDetailsDialog";
import customAreaDetails from "@/components/CustomAreaDetails";
import AccessibilityDialogueOnDashboard from "@/components/AccessibilityDialogueOnDashboard.vue";
import HeaderModule from "@/components/HeaderModule.vue";
import DashboardLegend from "@/components/DashboardLegend.vue";
import AvatarState from "@/components/AvatarState.vue";
import { exportCSVFile } from "@/mixins/ExportCSVFile";
import { useDisplay } from "vuetify";

export default {
  name: "DashboardPage",
  data: () => ({
    areas: [],
    displayTable: false,
    clientColourScheme: [],
    colourBandAccessibleText: {
      NONE: "not given an equal interval band",
      MIN: "low equal interval band",
      MID: "medium equal interval band",
      MAX: "high equal interval band",
    },
    categorisedCustomAreas: [],
    uncategorisedCustomAreas: [],
    showAreaSelector: false,
    timer: null,
    viewInfo: null,
    showAboutIndDialog: false,
    captionStart: "",
    captionDashboardName: "The default indicators",
    dataValuesBandingDictionary: [],
    dataValuesDictionary: {},
    dataInTable: {
      custom_area_categories: [],
      custom_area_categories_displayed: [],
      custom_areas: [],
      custom_areas_displayed: [],
      indicators: [],
      tableHeaderArray: [{ title: "Area", key: "name" }],
    },
    loadingAreasFromAreasDialog: false,
    customAreasToLoad: [],
    loadingIndicatorsFromDataDialog: false,
    indicatorsToLoad: [],
    initialData: {
      custom_area_categories: [],
      custom_areas: [],
      custom_default_theme: [],
      default_indicators: [],
      indicators: [],
      standard_indicators: [],
      themes: [],
      user_dashboards: [],
    },
    indicatorCallPayload: null,
    showSaveCustomDashboardButton: false,
    exportingCSV: false,
    showSaveCustomDashboardDialog: false,
    showCustomDashboardDialog: false,
    showDataComponentDialog: false,
    saveCustomDashboardProcessOngoing: false,
    customDashboardToDisplay: undefined,
    customDashboardToDelete: null,
    customDashboardToEdit: 0,
    customDashboardName: "",
    showDeleteCustomDashboardConfirmDialog: false,
    standardIndicatorsAsObject: {},
    themeLoadedToDashboard: false,
    loadingData: true,
    loadingPercentages: true,
    counter: 0,
    totalRequests: 0,
    progressPercentage: 0,
    doneRequests: 0,
    showCustomAreaDetailsDialog: false,
    customAreaToShow: {},
    constituentRegions: [],
    loadingConstituentRegions: false,
    areaDetails: [],
    pageHeight: useDisplay().height,
  }),
  components: {
    CustomAreaSelector,
    DashboardDataSelector,
    IndicatorDetailsDialog,
    AccessibilityDialogueOnDashboard,
    customAreaDetails,
    HeaderModule,
    DashboardLegend,
    AvatarState,
  },
  computed: {
    isDemoAccount() {
      return this.$store.state.config.customClientConfig.client_type === "demo"
        ? true
        : false;
    },
    colHeaderWidth: {
      get() {
        const calculatedWidth =
          screen.width / this.dataInTable.tableHeaderArray.length;
        return Math.min(calculatedWidth, 400);
      },
    },
    defaultDashboardID: {
      get() {
        let defaultDashboardID = null;
        for (var i = 0; i < this.initialData.user_dashboards.length; i++) {
          if (this.initialData.user_dashboards[i].dashboard) {
            defaultDashboardID = this.initialData.user_dashboards[i].id;
          }
        }
        return defaultDashboardID;
      },
      set(value) {
        for (var i = 0; i < this.initialData.user_dashboards.length; i++) {
          this.initialData.user_dashboards[i].dashboard =
            this.initialData.user_dashboards[i].id === value ? true : false;
        }
      },
    },
    nationalComparator: {
      get() {
        return this.$store.getters.nationalComparator;
      },
    },
    userHasCustomDashboardSaves() {
      return this.initialData.user_dashboards.length > 0;
    },
    caption() {
      return this.captionStart + " " + this.captionDashboardName;
    },
    defaultDashboardLoaded() {
      return (
        !this.showSaveCustomDashboardButton &&
        !this.customDashboardToDisplay &&
        !this.themeLoadedToDashboard
      );
    },
    customAreaDetails() {
      // get the category name
      var category = this.initialData.custom_area_categories.find(
        (cac) => cac.id == this.customAreaToShow.custom_area_category_id,
      );

      return {
        Name: this.customAreaToShow.name,
        Category: category ? category.name : "Unassigned",
        Description: this.customAreaToShow.description
          ? this.customAreaToShow.description
          : "No description",
        Type:
          this.customAreaToShow.type_id == 1
            ? "Standard Area"
            : this.customAreaToShow.type_id == 2
              ? "Draw on Map"
              : "Creation by Postcode",
        Created: this.customAreaToShow.created_at
          ? this.customAreaToShow.created_at.split("T")[0]
          : "",
      };
    },
    customDashboardToDeleteName() {
      return this.customDashboardToDelete
        ? this.customDashboardToDelete.name
        : "";
    },
    calculatedTableHeight() {
      // set these
      let topPanel = document.getElementById("headerModule");
      let legend = document.getElementById("dashboardLegend");
      let buttons = document.getElementById("dashboardButtons");
      let header = document.getElementById("appBar");

      if (!topPanel || !legend || !buttons || !header) return;

      // sum the height of the title and topPanel elements
      let calculatedHeight =
        topPanel.clientHeight +
        legend.clientHeight +
        buttons.clientHeight +
        header.clientHeight;

      // subtract the sum from the height of page
      return this.pageHeight - (calculatedHeight + 40);
    },
  },
  mounted() {
    this.fetchInitialData();
  },
  methods: {
    handleClose() {
      this.showCustomAreaDetailsDialog = false;
    },
    getAriaDescriptionText(index) {
      if (typeof this.dataValuesBandingDictionary[index] !== "undefined")
        return this.colourBandAccessibleText[
          this.dataValuesBandingDictionary[index]["band"]
        ];
    },
    getColor(index) {
      if (typeof this.dataValuesBandingDictionary[index] !== "undefined")
        return this.dataValuesBandingDictionary[index]["band_colour"];
    },
    getIcon(index) {
      if (
        !this.$store.state.config.userProfile.show_icons_on_dashboard ||
        typeof this.dataValuesBandingDictionary[index] === "undefined"
      ) {
        return "";
      }

      const band = this.dataValuesBandingDictionary[index]["band"];
      const icons = {
        MIN: "mdi-arrow-down-bold",
        MID: "mdi-format-align-middle",
        MAX: "mdi-arrow-up-bold",
      };

      return { icon: icons[band], level: band };
    },
    isDarkColor(color_hex) {
      if (typeof color_hex !== "undefined")
        return this.getColorBrightness(color_hex) < 127;
    },
    getColorBrightness(color_hex) {
      let amount_of_red = parseInt(color_hex.substr(0, 2), 16);
      let amount_of_green = parseInt(color_hex.substr(2, 2), 16);
      let amount_of_blue = parseInt(color_hex.substr(4, 2), 16);
      return (
        (amount_of_red * 299 + amount_of_green * 587 + amount_of_blue * 114) /
        1000
      );
    },
    showMetadataDialog(indicator) {
      if (indicator !== "name") {
        this.viewInfo = null;
        this.showAboutIndDialog = true;
        var indicatorObject = this.initialData.indicators.find(
          (ind) => ind.indicator_code == indicator,
        );
        // call this
        this.$axios
          .get("/standard-metadata/" + indicatorObject.id)
          .then(
            function (response) {
              // handle success
              this.viewInfo = { indicatorInfo: response.data };
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Failed to search indicators",
                timeout: -1,
                colour: "error",
              });
            }.bind(this),
          );
      }
    },
    addCACandCAToDataTable() {
      if (this.loadingAreasFromAreasDialog) {
        this.dataInTable.custom_area_categories = this.$cloneDeep(
          this.getCACwithCustomAreas().filter((cac) =>
            this.initialData.custom_areas
              .filter((custom_area) =>
                this.customAreasToLoad.includes(custom_area.id),
              )
              .map((ca) => ca.custom_area_category_id)
              .includes(cac.id),
          ),
        );
        this.dataInTable.custom_areas = this.$cloneDeep(
          this.initialData.custom_areas.filter((custom_area) =>
            this.customAreasToLoad.includes(custom_area.id),
          ),
        );
        // reset the flag
        this.loadingAreasFromAreasDialog = false;
      } else {
        // display all the custom areas - this is the initial page state
        this.dataInTable.custom_area_categories = this.$cloneDeep(
          this.getCACwithCustomAreas(),
        );
        this.dataInTable.custom_areas = this.$cloneDeep(
          this.initialData.custom_areas,
        );
      }
      // added for lazy loading the content on the dashbaord as users scroll
      this.updateCustomAreasToDisplay();
    },
    getCustomAreasInCACForDisplay(cac_id) {
      return this.dataInTable.custom_areas_displayed.filter(
        (custom_area) => custom_area.custom_area_category_id == cac_id,
      );
    },
    updateCustomAreasToDisplay() {
      // first get the areas to display
      this.dataInTable.custom_areas_displayed = this.$cloneDeep(
        this.dataInTable.custom_areas,
      );
      // now get the custom area categories to display
      this.dataInTable.custom_area_categories_displayed = this.$cloneDeep(
        this.dataInTable.custom_area_categories,
      );
    },
    getCustomAreasInCACForExport(cac_id) {
      return this.dataInTable.custom_areas.filter(
        (custom_area) => custom_area.custom_area_category_id == cac_id,
      );
    },
    getInitialCustomAreasInCAC(cac_id) {
      return this.initialData.custom_areas.filter(
        (custom_area) => custom_area.custom_area_category_id == cac_id,
      );
    },
    getCACwithCustomAreas() {
      return this.initialData.custom_area_categories.filter(
        (element) => this.getInitialCustomAreasInCAC(element.id).length > 0,
      );
    },
    /*    getCACwithCustomAreasForDisplay() {
      return this.dataInTable.custom_area_categories.filter(
        (element) => this.getCustomAreasInCACForDisplay(element.id).length > 0,
      );
    },*/
    getData(id) {
      if (this.dataValuesDictionary[id] != "-") {
        return this.dataValuesDictionary[id];
      } else {
        return null;
      }
    },
    getRawDataForCSV(id) {
      return this.getData(id);
    },
    updateHeaderArray(newIndicatorsArray) {
      let newArray = [{ title: "Area", key: "name" }];
      newIndicatorsArray.forEach((element) => {
        newArray.push({
          title: element.indicator_name,
          key: element.indicator_code,
        });
      });
      this.dataInTable.tableHeaderArray = newArray;
    },
    fetchInitialData() {
      this.$axios
        .get("/client-colour-scheme")
        .then(
          function (response) {
            this.clientColourScheme = response.data;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get colour scheme",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );

      this.$axios
        .get("/dashboard")
        .then(
          function (response) {
            // handle success
            // store all of the data received
            this.initialData = response.data;
            this.initialData.indicators = response.data.standard_indicators;
            this.standardIndicatorsAsObject = this.arrayToKeyValueObject(
              this.initialData.indicators,
              "indicator_code",
            );

            // display the default set of indicators on the dashboard
            this.dataInTable.indicators = this.initialData.indicators.filter(
              (indicator) => {
                return (
                  this.initialData.indicators_to_display_on_load.includes(
                    indicator.indicator_code,
                  ) &&
                  (indicator.demo_indicator ?? true)
                );
              },
            );
            // if a user has overridden the default dashboard indicators defined for the client change the display name
            if (this.initialData.custom_default_theme.length != 0) {
              this.customDashboardToDisplay =
                this.initialData.user_dashboards.find(
                  (element) =>
                    element.id == response.data.custom_default_theme[0].id,
                );
              this.captionDashboardName =
                'The "' +
                this.customDashboardToDisplay.name +
                '" custom dashboard';
            }
            this.addCACandCAToDataTable();
            this.updateHeaderArray(this.dataInTable.indicators);
            this.showAreaSelector = true;
            setTimeout(
              function () {
                this.loadingData = false;
              }.bind(this),
              500,
            );
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get all the themes",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    splitObject(obj) {
      var result = [];
      var keys = Object.keys(obj);

      var lengthOfChunks = 600; // indicators * custom areas / lengthOfChunks = number of chunks, e.g. 113 * 338 / 600 = 64 chunks

      for (let i = 0; i < keys.length; i += lengthOfChunks) {
        var start = i;
        var end = Math.min(i + lengthOfChunks, keys.length);
        var newObj = {};

        for (var j = start; j < end; j++) {
          newObj[keys[j]] = obj[keys[j]];
        }

        result.push(newObj);
      }

      this.totalRequests = result.length;

      return result;
    },
    packageData(csv = false) {
      // build the data structure we need
      let a = {};

      // national comparator
      this.dataInTable.indicators.forEach((indicator) => {
        let nc = "nc." + indicator.indicator_code + "." + indicator.date;
        if (csv) {
          // ask the server for the data
          a[nc] = null;
        } else {
          // if we do send that to the server to get the same value reflected back
          a[nc] = this.dataValuesDictionary[nc] || null;
        }
      });

      // custom areas
      this.dataInTable.custom_areas.forEach((ca) => {
        this.dataInTable.indicators.forEach((indicator) => {
          let s =
            "" + ca.id + "." + indicator.indicator_code + "." + indicator.date;
          // check if we have the data already
          if (csv) {
            a[s] = null;
          } else {
            // if we do send that to the server to get the same value reflected back
            a[s] = this.dataValuesDictionary[s] || null;
          }
        });
      });

      return a;
    },
    fetchDataValuesForTable() {
      this.loadingData = true;

      // only do the work if there are both custom areas and indicators
      if (
        this.dataInTable.custom_areas.length == 0 ||
        this.dataInTable.indicators.length == 0
      ) {
        this.emit.emit("systemMessage", {
          title:
            "Either the areas or indicators are missing so we did not get the data for the dashboard",
          message: "Please try again",
          timeout: -1,
          colour: "red",
        });
        return;
      }

      let a = this.packageData();

      // split the object into smaller objects
      this.indicatorCallPayload = this.splitObject(a);

      // if it's small chunk then we don't need to show the progress bar
      this.loadingPercentages = this.totalRequests !== 1;

      // make calls to get the data step by step to determine the progress for user
      this.dataValuesDictionary = {};
      this.doneRequests = 0;
      this.getValuesByArea();
    },
    getValuesByArea() {
      var startTime = this.timer;
      this.$axios
        .post(
          "/dashboard/complete-data",
          this.indicatorCallPayload[this.doneRequests],
        )
        .then(
          function (response) {
            // merge the data received with the data we already have
            this.dataValuesDictionary = {
              ...this.dataValuesDictionary,
              ...response.data.values,
            };

            // another one down
            this.doneRequests++;
            this.progressPercentage = Math.round(
              (this.doneRequests / this.totalRequests) * 100,
            );
            // the edit custom dashboard has a different user feedback mechanism
            if (this.saveCustomDashboardProcessOngoing) {
              this.saveCustomDashboardProcessOngoing = false;
            }
            // Keep looping until we've got all the data
            if (this.doneRequests < this.totalRequests) {
              // Limit api requests to one per 1.1 seconds to keep under the rate limit of 60 per minute
              setTimeout(
                function () {
                  this.getValuesByArea();
                }.bind(this),
                Math.max(1100 - (this.timer - startTime), 0),
              );
            } else {
              // We've finally collected all the data, so work out the colour bandings across all data now
              this.getColorBandings();
              setTimeout(
                function () {
                  this.loadingData = false;
                  this.displayTable = true;
                }.bind(this),
                500,
              );
              this.loadingPercentages = false;
              this.doneRequests = 0;
              this.progressPercentage = 0;

              this.dataInTable.custom_areas_displayed = this.$cloneDeep(
                this.dataInTable.custom_areas,
              );
              this.dataInTable.custom_area_categories_displayed =
                this.$cloneDeep(this.dataInTable.custom_area_categories);

              setTimeout(() => {
                this.truncateHeaderText();
              }, 500);
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            // the edit custom dashboard has a different user feedback mechanism
            if (this.saveCustomDashboardProcessOngoing) {
              this.saveCustomDashboardProcessOngoing = false;
            } else {
              this.emit.emit("systemBusy", false);
            }
            // handle error
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get all the themes",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    // A slimmed down version of getValuesByArea(), used to just work out the colour bandings
    // but across all the data we have
    getColorBandings() {
      this.$axios
        .post("/dashboard/complete-data", this.dataValuesDictionary)
        .then(
          function (response) {
            // update the bandings
            this.dataValuesBandingDictionary = response.data.bandings;
          }.bind(this),
        )
        .catch(
          function (error) {
            // the edit custom dashboard has a different user feedback mechanism
            if (this.saveCustomDashboardProcessOngoing) {
              this.saveCustomDashboardProcessOngoing = false;
            } else {
              this.emit.emit("systemBusy", false);
            }
            // handle error
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to calculate colour bandings",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    truncateHeaderText() {
      const headers = document.querySelectorAll(".headerText");
      const maxLines = 3; // Maximum lines to allow
      const lineHeight = 21; // Approximate line-height in pixels

      headers.forEach((header) => {
        const maxHeight = maxLines * lineHeight;

        // If the content is already within the max height, skip truncating
        if (header.scrollHeight <= maxHeight) return;

        let text = header.textContent.trim();

        // Loop to trim text until it fits within max height
        while (header.scrollHeight > maxHeight && text.length > 0) {
          text = text.slice(0, -1); // Remove last character
          header.textContent = text.trim() + "...";
        }
      });
    },
    arrayToKeyValueObject(arr, key) {
      return arr.reduce((obj, item) => {
        obj[item[key]] = item;
        return obj;
      }, {});
    },
    loadNewAreas(data) {
      if (data.length) {
        this.loadingAreasFromAreasDialog = true;
        this.customAreasToLoad = data; // this is an array of custom_area.id 's
        // check if we have all the data for the indicator. If we don't trigger a fetch to get the data
        // @future the check could be made more sensitive but this works for now
        this.addCACandCAToDataTable();
        this.fetchDataValuesForTable();
      }
    },
    getDetailedArea(area) {
      this.$axios
        .post("/get-custom-area-details", [area.id])
        .then(
          function (response) {
            let customAreaDetails = response.data;
            this.customAreaToShow = customAreaDetails[0];
          }.bind(this),
        )
        .catch(
          function (error) {
            console.log(error);
          }.bind(this),
        );
    },
    loadNewIndicators() {
      this.dataInTable.indicators = [];
      this.indicatorsToLoad.forEach((indicator) => {
        // check the indicator exists
        if (this.standardIndicatorsAsObject[indicator]) {
          // and if it does
          this.dataInTable.indicators.push(
            this.initialData.indicators.find(
              ({ indicator_code }) => indicator_code == indicator,
            ),
          );
        }
      });

      this.updateHeaderArray(this.dataInTable.indicators);
      this.fetchDataValuesForTable();
    },
    saveEditedCustomDashboard({ data, flag, caption, id, name }) {
      this.customDashboardName = name;
      this.indicatorsToLoad = data;
      this.showSaveCustomDashboardButton = flag;
      this.captionDashboardName = caption;
      this.themeLoadedToDashboard = false;

      this.editCustomDashboard(id);
    },
    loadIndicatorsFromDataComponent({ data, flag, caption, theme }) {
      this.loadingIndicatorsFromDataDialog = true;
      this.indicatorsToLoad = data;
      this.showSaveCustomDashboardButton = flag;
      this.themeLoadedToDashboard = theme;
      this.captionDashboardName = caption;
      this.customDashboardToDisplay = undefined;
      this.customDashboardName = "";

      // added for lazy loading the content on the dashbaord as users scroll
      this.updateCustomAreasToDisplay();

      this.loadNewIndicators();
    },
    saveCustomDashboard() {
      this.showSaveCustomDashboardDialog = false;
      this.emit.emit("systemBusy", true);
      let name = this.customDashboardName
        ? this.customDashboardName
        : "custom-dashboard-" + new Date();
      this.$axios
        .post("/dashboard/save-custom-theme", {
          name: name,
          indicators: this.indicatorsToLoad,
        })
        .then(
          function (response) {
            this.initialData.user_dashboards = response.data.user_dashboards;
            this.customDashboardToDisplay =
              this.initialData.user_dashboards.find(
                (element) => element.id == response.data.new_user_dashboard.id,
              );
            this.captionDashboardName =
              'The "' +
              this.customDashboardToDisplay.name +
              '" custom dashboard';
            this.showSaveCustomDashboardButton = false;
            this.customDashboardName = "";
            this.emit.emit("systemMessage", {
              title: "Custom dashboard saved",
              message: "Success!",
              timeout: 3000,
              colour: "green",
            });
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemBusy", false);
            this.customDashboardName = "";
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to save the custom dashboard",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    initiateViewCustomDashboard(item) {
      this.customDashboardToDisplay = item;
      this.loadCustomDashboard();
    },
    loadCustomDashboard() {
      this.showCustomDashboardDialog = false;
      this.loadingIndicatorsFromDataDialog = true;
      this.themeLoadedToDashboard = false;
      this.indicatorsToLoad =
        this.customDashboardToDisplay.user_dashboard_theme_indicator_matrices.map(
          (element) => element.indicatorCode,
        );
      this.captionDashboardName =
        'The "' + this.customDashboardToDisplay.name + '" custom dashboard';
      this.showSaveCustomDashboardButton = false;
      // added for lazy loading the content on the dashbaord as users scroll
      this.updateCustomAreasToDisplay();
      this.loadNewIndicators();
    },
    viewDefaultDashboard() {
      this.customDashboardToDisplay = undefined;
      this.showSaveCustomDashboardButton = false;
      this.themeLoadedToDashboard = false;
      this.loadingIndicatorsFromDataDialog = false;
      this.indicatorsToLoad = this.initialData.default_indicators;
      this.captionDashboardName = "The default indicators";
      // added for lazy loading the content on the dashbaord as users scroll
      this.updateCustomAreasToDisplay();
      this.loadNewIndicators();
    },
    initiateDeleteCustomDashboard(item) {
      this.customDashboardToDelete = item;
      this.showDeleteCustomDashboardConfirmDialog = true;
    },
    deleteCustomDashboard() {
      this.showDeleteCustomDashboardConfirmDialog = false;
      this.emit.emit("systemBusy", true);
      this.$axios
        .delete("/delete-custom-theme/" + this.customDashboardToDelete["id"])
        .then(
          function (response) {
            // handle success
            this.initialData.user_dashboards = response.data.user_dashboards;
            this.customDashboardToDelete = null;
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            this.customDashboardToDelete = null;
            this.emit.emit("systemBusy", false);
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get all the themes",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    initiateEditCustomDashboard(item) {
      this.showDataComponentDialog = true;
      this.customDashboardToEdit = item.id;
    },
    editCustomDashboard(id) {
      let name = this.customDashboardName
        ? this.customDashboardName
        : "custom-dashboard-" + new Date();

      this.emit.emit("systemMessage", {
        title: 'The "' + name + '" dashboard is being updated',
        message: "Custom dashboard being updated",
        timeout: 2000,
        colour: "warning",
      });
      this.$axios
        .put("/dashboard/update-custom-theme/" + id, {
          name: name,
          indicators: this.indicatorsToLoad,
        })
        .then(
          function (response) {
            this.initialData.user_dashboards = response.data.user_dashboards;
            this.customDashboardToDisplay =
              this.initialData.user_dashboards.find(
                (element) => element.name == name,
              );
            this.customDashboardName = "";
            this.emit.emit("systemMessage", {
              title: "Custom dashboard updated",
              message: "Success!",
              timeout: 3000,
              colour: "green",
            });

            // added for lazy loading the content on the dashbaord as users scroll
            this.updateCustomAreasToDisplay();

            this.showDataComponentDialog = false;
            this.customDashboardToEdit = 0;

            // this.loadNewIndicators();
          }.bind(this),
        )
        .catch(
          function (error) {
            this.customDashboardName = "";
            // handle error
            console.error(error);
            this.saveCustomDashboardProcessOngoing = false;
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to update the custom dashboard",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    saveAsDefaultDashboard() {
      let val = this.defaultDashboardID ? this.defaultDashboardID : 0;
      this.emit.emit("systemBusy", true);
      this.$axios
        .put("/dashboard/set-default-dashboard/" + val)
        .then(
          function (response) {
            this.initialData.user_dashboards = response.data.user_dashboards;
            this.emit.emit("systemBusy", false);
            this.emit.emit("systemMessage", {
              title: "Dashboard Update Complete",
              message: "Success!",
              timeout: 3000,
              colour: "green",
            });
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            this.emit.emit("systemBusy", false);
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to set a new default dashboard",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    download() {
      // no download if a demo account
      if (this.isDemoAccount) {
        return;
      }

      this.exportingCSV = true;

      // fetch the data as we need to get all the decimal places
      var data = this.packageData(true);

      this.$axios
        .post("/dashboard/complete-data-for-csv", data)
        .then((response) => {
          // handle success
          data = response.data.values;

          // build the csv file
          let date_text;
          let headers = { category: "Category" };
          this.dataInTable.tableHeaderArray.forEach((element) => {
            headers[element.key] = element.title;
          });

          let items = [];
          let header_keys = Object.keys(headers);

          //reference date
          let meta_ref = {};
          header_keys.forEach((header) => {
            switch (header) {
              case "category":
                meta_ref[header] = "Reference Date";
                break;

              case "name":
                meta_ref[header] = "";
                break;

              default:
                meta_ref[header] = this.dataInTable.indicators.find(
                  (i) => i.indicator_code === header,
                )["date_coverage_text"];
            }
          });

          items.push(meta_ref);

          //update frequency
          let meta_upd = {};
          header_keys.forEach((header) => {
            switch (header) {
              case "category":
                meta_upd[header] = "Update Frequency";
                break;

              case "name":
                meta_upd[header] = "";
                break;

              default:
                meta_upd[header] = this.dataInTable.indicators.find(
                  (i) => i.indicator_code === header,
                )["update_frequency"];
            }
          });

          items.push(meta_upd);

          // add National Comparator data
          let nc_obj = {};
          header_keys.forEach((header) => {
            switch (header) {
              case "category":
                nc_obj[header] = this.nationalComparator.cac;
                break;

              case "name":
                nc_obj[header] = this.nationalComparator.name;
                break;

              default:
                date_text = this.getIndicator(header).date;
                nc_obj[header] = data["nc." + header + "." + date_text];
                break;
            }
          });

          items.push(nc_obj);

          // add the Custom Area data but do it in the same style as the data table display
          this.dataInTable.custom_area_categories.forEach((cac) => {
            this.getCustomAreasInCACForExport(cac.id).forEach((ca) => {
              let obj = {};
              let date_text;
              header_keys.forEach((header) => {
                switch (header) {
                  case "category":
                    obj[header] = cac.name;
                    break;

                  case "name":
                    obj[header] = ca.name;
                    break;

                  default:
                    date_text = this.getIndicator(header).date;
                    obj[header] =
                      data["" + ca.id + "." + header + "." + date_text];

                    break;
                }
              });

              items.push(obj);
            });
          });

          // Unassigned category
          this.getCustomAreasInCACForExport(null).forEach((ca) => {
            let obj = {};
            let date_text;
            header_keys.forEach((header) => {
              switch (header) {
                case "category":
                  obj[header] = "Unassigned";
                  break;

                case "name":
                  obj[header] = ca.name;
                  break;

                default:
                  date_text = this.getIndicator(header).date;
                  obj[header] =
                    data["" + ca.id + "." + header + "." + date_text];

                  break;
              }
            });

            items.push(obj);
          });

          let fileTitle = "dashboard-" + new Date();

          exportCSVFile(headers, items, fileTitle);
        })
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Building the csv file failed",
              message: error.response.data.message,
              timeout: 0,
              colour: "error",
            });
          }.bind(this),
        )
        .finally(() => {
          this.exportingCSV = false;
        });
    },
    getIndicator(ind_code) {
      return this.dataInTable.indicators.find(
        ({ indicator_code }) => indicator_code == ind_code,
      );
    },
  },
  watch: {
    timer: {
      handler() {
        setTimeout(
          function () {
            this.timer += 200;
          }.bind(this),
          200,
        );
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.v-btn {
  /* Temporarily use !important for now as dashboard styling needs rework */
  margin: 5px;
}
.v-table--density-compact {
  --v-table-header-height: 28px;
  --v-table-row-height: 28px;
}

.headerText {
  text-align: center;
  /* white-space: nowrap; */
  overflow: hidden;
  /* text-overflow: ellipsis; */
}

/* first headerText under the "testOne" shoudn't have the underlining*/
.headerText .testOne:first-child {
  text-decoration: none;
}

.light-text {
  color: #f0f0f0;
}
.spinner {
  top: -15vh;
}

.custom-dash-list-item {
  font-size: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 280px;
}

.custom-dashboard-col {
  padding-top: 3px;
  padding-bottom: 3px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

#ocsi-dashboard .cac,
.comparatorTitle {
  font-weight: bolder;
}

caption {
  font-size: xx-large;
  margin: 12px auto;
}

.ca::before {
  padding: 0px 10px;
}

.ca {
  cursor: pointer;
  text-decoration: underline;
}

td:not(.ca, .cac, .comparatorTitle, .comparator) {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.07);
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.legend-colour {
  height: 10px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.legend-text {
  height: 10px;
}
.v-data-table.legend {
  margin: 0px 10px;
}

.cac:hover,
.comparatorTitle:hover,
.comparator:hover {
  background-color: white !important;
}

.region-item {
  display: inline-block;
  border-color: #e6e6e6;
  border-style: solid;
  border-radius: 10px;
  padding: 2px;
  background-color: #e6e6e6;
  margin: 2px;
}

.legend-text {
  font-size: 12px;
  width: 180px;
  padding: 5px;
  margin: 5px;
}

.legend-colour {
  width: 180px;
  padding: 5px;
  margin: 5px !important;
}

.legend {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
